import React from 'react';
import PageWrapper from '../containers/PageWrapper';
import WebsiteWrapper from '../parts/WebsiteWrapper';
import WorkshopLp from '../organisms/LeadMagnetLPlayout';

const WorkshopLp2Root = ({ location, pageContext }) => {
  const { lang, translations } = pageContext;

  return (
    <PageWrapper lang={lang} translations={translations}>
      <WebsiteWrapper isNavBgWhite={true} location={location}>
        <WorkshopLp translations={translations} type="lp2" />
      </WebsiteWrapper>
    </PageWrapper>
  );
};

export default WorkshopLp2Root;
